import React, { useState, useEffect } from 'react';
import './css/bootstrap.min.css';
import './css/main.css';
import './css/now-ui-kit.css';
import './App.css';
import './css/auditlog.css';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import Cookies from 'js-cookie';
import axios from 'axios';
import { useLoginManager } from './components/loginManager';
import getUserData from './components/getUserData';
import { useBackendAddress } from './components/backendAddress';

const AuditLogs = ({ }) => {
	const { t } = useTranslation();
	const [auditLogs, setAuditLogs] = useState([]);
    const { loggedIn, logoutFunction } = useLoginManager();

    const [nickname, email, mobile, pfpUrl, lvl, exp, expNeeded, status, userId, roleId, verified, roleBadgeComponent, renderVerifiedBadge] = getUserData();
  
    const { backendAddress } = useBackendAddress();

	const fetchAuditLogs = async (page) => {
		const sessionCookie = Cookies.get('session');
		try {
		  const response = await fetch(`${backendAddress}/data/auditlog/get?page=1&userId=${userId}`, {
			method: 'GET',
			headers: {
			  'Content-Type': 'application/json',
			  'Cookie': sessionCookie,
			  Authorization: `Bearer ${sessionCookie}`,
			},
		  });
	  
		  if (!response.ok) {
			throw new Error(`HTTP error! Status: ${response.status}`);
		  }

		  if (response.status === 401) {
			await logoutFunction();
		  }
	  
		  const data = await response.json();
		  setAuditLogs(data.auditLogs);
		} catch (error) {
		  console.error('Error fetching audit logs:', error);
		}
	};

    useEffect(() => {
        if (loggedIn) {
            fetchAuditLogs();
        }
    }, [loggedIn]);

	const formatDate = (dateString) => {
        const options = { 
            year: 'numeric', 
            month: 'long', 
            day: 'numeric', 
            hour: '2-digit', 
            minute: '2-digit', 
            second: '2-digit', 
            hour12: false 
        };
        const date = new Date(dateString);
        return date.toLocaleString(options);
    };

	useEffect(() => {
        if (!loggedIn) {
            //navigateToHome();
        }
    }, [loggedIn]);

    const navigateToHome = () => {
        window.location.href = '/';
    }

    return (
        <div>
            <Helmet>
                <title>{t('audit_h')} | LimroNet</title>
            </Helmet>
            <div className='auditlog_container'>
                <h1 className="display-5 title" style={{ color: 'white', textAlign: 'center', borderBottom: '1px solid white', marginBottom: '10px'}}>
                    {t('audit_header')}
                </h1>
                <table>
                    <thead>
                        <tr>
                            <th>{t('audit_msg')}</th>
                            <th>{t('audit_ip')}</th>
                            <th>{t('audit_date')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {auditLogs.map((log, index) => (
                            <tr key={index}>
                                <td>{log.message}</td>
								<td>{log.ipAddress}</td>
                        		<td>{formatDate(log.timeStamp)}</td>
                            </tr>
                        ))}
                    </tbody>
				</table>
            </div>
        </div>
    );
};

export default AuditLogs;