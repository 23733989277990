import { useState, useEffect } from "react";
import nopfp from '../assets/noserverpfp.png';
import axios from 'axios';
import Cookies from "js-cookie";

//components
import Post from "./Post";
import { useLoginManager } from './loginManager';
import getUserData from './getUserData';
import TextWithLineBreaks from "./textWithLineBreaks";
import { useBackendAddress } from "./backendAddress";

const RenderFYP = () => {
    const { loggedIn, logoutFunction } = useLoginManager();
    const [postsData, setPostsData] = useState([]);

    const { backendAddress } = useBackendAddress();

    const [nickname, email, mobile, pfpUrl, lvl, exp, expNeeded, status, userId, roleId, verified, roleBadgeComponent, renderVerifiedBadge] = getUserData();

    useEffect(() => {
        if (loggedIn) {
            getPosts();
        }
    }, [loggedIn]);

    const getPosts = async () => {
        try {
            const sessionId = Cookies.get('session');
            const response = await axios.get(`${backendAddress}/data/posts/get?userId=${userId}`, {
                headers: {
                    Authorization: `Bearer ${sessionId}`
                }
            });
            if (response.status === 401) {
                await logoutFunction();
            }
            // Sort postsData in descending order based on postTimeStamp
            const filteredPostsData = response.data.data.filter(post => post.deleted !== 1);

            // Sort postsData in descending order based on postTimeStamp
            const sortedPostsData = filteredPostsData.sort((a, b) => new Date(b.postTimeStamp) - new Date(a.postTimeStamp));
            
            // Set the sorted and filtered posts data
            setPostsData(sortedPostsData);
        } catch (error) {
            console.error('Error fetching author:', error);
        }
    }

    return (
        <div>
            {loggedIn ? (
                postsData.map((post, index) => (
                    <Post
                        key={index}
                        postId={post.postId}
                        postTitle={post.postTitle}
                        postContent={<TextWithLineBreaks text={post.postMessage}/>}
                        authorUserId={post.postSenderId}
                        date={post.postTimeStamp}
                    />
                ))
            ) : (
                <p>401</p>
            )}
        </div>
    );
};

export default RenderFYP;