import { useState, useEffect } from 'react';
import { useLoginManager } from './loginManager';
import Cookies from 'js-cookie';
import axios from 'axios';

import { useBackendAddress } from "./backendAddress";

const useUserData = () => {
    const { loggedIn, logoutFunction } = useLoginManager();

    const [nickname, setNickname] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [pfpUrl, setPfpUrl] = useState('');
    const [lvl, setLvl] = useState(null);
    const [exp, setExp] = useState(null);
    const [expNeeded, setExpNeeded] = useState(null);
    const [status, setUserStatus] = useState('');
    const [userId, setUserId] = useState(null);

    //for displaying things
    const [roleId, setRoleId] = useState(null);
    const [verified, setVerified] = useState(null);

    const { backendAddress } = useBackendAddress();

    useEffect(() => {
        if (loggedIn) {
            const sessionId = Cookies.get('session');
            const getData = async () => {
                try {
                    const response = await axios.get(`${backendAddress}/data/get`, {
                        headers: {
                            Authorization: `Bearer ${sessionId}`
                        }
                    });
                    const userData = response.data.data;
                    setNickname(userData.username);
                    setEmail(userData.email);
                    setMobile(userData.mobile);
                    setPfpUrl(userData.image_url);
                    setLvl(userData.lvl);
                    setExp(userData.exp);
                    setExpNeeded(userData.exp_needed);
                    setUserStatus(userData.status);
                    setUserId(userData.id);
                    setRoleId(userData.role_id);
                    setVerified(userData.verified);

                    if (response.status === 401) {
                        await logoutFunction();
                    }
                } catch (error) {
                    console.error('Error fetching user data:', error);
                    //logoutFunction();
                }
            };
            getData();
        }
    }, [loggedIn, logoutFunction]);

    const roleBadgeComponent = () => {
        if(roleId === 0){
            return (
                <span class="badge badge-default">Member</span>
            );
        } else if(roleId === 1){
            return (
                <span class="badge badge-success">Moderator</span>
            );
        } else if(roleId === 2){
            return (
                <span class="badge badge-danger">Administrator</span>
            );
        } else {
            return (
                <span class="badge badge-default">Undefined</span>
            );
        }
    };

    const renderVerifiedBadge = () => {
        if (verified === 1) {
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 25 25" fill="white">
                    <path d="M8 13l3.5 3.5 7-7" fill="none" stroke="#FFF" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            );
        } else {
            return null;
        }
    };

    return [nickname, email, mobile, pfpUrl, lvl, exp, expNeeded, status, userId, roleId, verified, roleBadgeComponent, renderVerifiedBadge];
};

export default useUserData;