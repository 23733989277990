import React from 'react';
import nopfp from '../assets/noserverpfp.png';
import '../css/home.css';

const DirectMessageEntry = ({ sender, message, profilePicture, onClick }) => {
    return (
        <div className="dm-entry" onClick={onClick}>
            <img src={nopfp} alt={`${sender}'s Profile`} className='dm-profile-picture' />
            <div className="dm-content">
                <div className="dm-sender">{sender}</div>
                <div className="dm-message">{message}</div>
            </div>
        </div>
    );
};

export default DirectMessageEntry;