import React from 'react';
import './css/bootstrap.min.css';
import './css/main.css';
import './css/now-ui-kit.css';
import './App.css';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

const ServiceUnavailable = ({ serviceAvailable, message, ipv4Address }) => {
    const { t } = useTranslation();

        return (
        <div className='header3 fullscreen_container'>
            <Helmet>
                <title>Service Unavailable | LimroNet</title>
            </Helmet>
            <h1 className='title'>Sorry, this service is not available for now!</h1>
            <p className='title4'>Reason: {message}</p>
            <p className='title4'>Request IP: {ipv4Address}</p>
        </div>
        );
};

export default ServiceUnavailable;