import React, { useState, useEffect } from 'react';
import './css/bootstrap.min.css';
import './css/main.css';
import './css/now-ui-kit.css';
import './App.css';
import './css/auditlog.css'; // Import profile.css
import './css/profile.css';
import './css/loginAndRegister.css';
import './css/home.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import nopfp from './assets/noserverpfp.png';
import Cookies from 'js-cookie';
import axios from 'axios';
import { Link } from 'react-router-dom';
import GamblingProfile from './GamblingProfile';
import LanguagesProfile from './LanguagesProfile';

//components
import { useLoginManager } from './components/loginManager';
import getUserData from './components/getUserData';
import { use } from 'i18next';

const Profile = ({ showNavAndFoot, setShowNavAndFoot, showLogregFoot, setShowLogregFoot }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const path = location.pathname;
    const [badgesData, setBadgesData] = useState({ badges: null });
    const [isProfileVisible, setIsProfileVisible] = useState(true);

    const [sendPostBtnDisabled, setSendPostBtnDisabled] = useState(true);
    const [postTitle, setPostTitle] = useState('');
    const [postContent, setPostContent] = useState('');

    const [recentPosts, setRecentPosts] = useState([]);

    //loggedIn
    const {loggedIn, logoutFunction} = useLoginManager();
    //getData
    const [nickname, email, mobile, pfpUrl, lvl, exp, expNeeded, status, userId, roleId, verified, roleBadgeComponent, renderVerifiedBadge] = getUserData();

    useEffect(() => {
        if (!loggedIn) {
            console.log(loggedIn);
            //navigateToHome();
        }
    }, [loggedIn]);

    const navigateToHome = () => {
        window.location.href = '/';
    }

    useEffect(() => {
        if (postContent.trim()) {
            setSendPostBtnDisabled(false);
        } else {
            setSendPostBtnDisabled(true);
        }
    }, [postContent]);

    const handleKeyPress = (e) => {
        if(e.key === 'Enter' && !sendPostBtnDisabled){
            handlePostSend();
        }
    }

    const handlePostSend = () => {
        if (postContent.trim() !== '') {
          setRecentPosts([...recentPosts, postContent]);
          setPostContent('');
        }
      };

      const toLogin = () => {
        window.location.href = '/login';
    }
    return (
        <div>
            <Helmet>
                <title>{t('profile_profile')} | LimroNet</title>
            </Helmet>
            {loggedIn ? (
            <div className='auditlog_container'>
                <h1 className="display-5 title">
                {t('profile_profile')}
                </h1>
                <div className='main-profile-container'>
                <div className='profile-container'>
                <div className="profile-image-container">
                    <center>
                        <img
                            src={nopfp}
                            alt="User Profile"
                            className="profile-image"
                        />
                    </center>
                    <div className="profile-details">
                        <center>
                            <p>{nickname ?? 'null'} {renderVerifiedBadge()}</p>
                            {roleBadgeComponent()}
                        </center>
                        <div className="dropdown-divider"></div>
                        <p>{t('profile_badges')}: {badgesData.badges || 'null'}</p>
                        <p>{t('profile_lvl')}: {lvl ?? 'null'}</p>
                        <p>{t('profile_exp')}: {exp ?? 'null'}/{expNeeded ?? 'null'}</p>
                        <div className="dropdown-divider"></div>
                        <p>{t('profile_id')}: {userId ?? 'null'}</p>
                        <div className="dropdown-divider"></div>
                        <p className='sb_footer-below-links'><Link to="/profile/auditlog">{t('profile_your_audit')}</Link></p>
                    </div>
                </div>
                </div>
                    <div className='containerrr heading3'>
                    <div className="recent-posts-container">
                        <h1 className="display-5 title2 heading3">Your recent posts</h1>
                        <div className="new-post-container">
                        <input type='email' placeholder='Enter post title' className='input_lr' onChange={(e) => setPostTitle(e.target.value)} onKeyPress={handleKeyPress}/>
                        <textarea 
                            type="new_post" 
                            placeholder="Write what is on your mind" 
                            className="new-post-input" 
                            onChange={(e) => setPostContent(e.target.value)} 
                            onKeyPress={handleKeyPress}
                        />
                        </div>
                        <div>
                        <button 
                            className="btn2" 
                            disabled={sendPostBtnDisabled} 
                            onClick={handlePostSend}
                        >
                            Send
                        </button>
                        </div>
                        <div className="posts-list">
                            {recentPosts.map((post, index) => (
                                <div>
                                    <img src={nopfp} className="post-pfp" alt="Profile Picture"/>
                                    <div key={index} className="post-item">{post}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            ) : (
                <div className='header3 notLoggedInContainer'>
                    <center>
                    <h1 className='display-5 title'>Want to check profiles?</h1>
                    <p>Log in and we will let you to check other people profiles!</p>
                    <a onClick={toLogin}>Login</a>
                    </center>
            </div>
            )}
        </div>
    );
}

export default Profile;