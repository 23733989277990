// useServiceAvailability.js
import { useEffect, useState } from "react";
import axios from "axios";

import { useBackendAddress } from "./backendAddress";

export const useServiceAvailability = () => {
    const [serviceAvailable, setServiceAvailable] = useState(false);
    const [message, setMessage] = useState('');
    const [ipv4Address, setIPV4Address] = useState(null);
    const { backendAddress } = useBackendAddress();

    const ServiceAvailabilityCheck = async () => {
        try {
            const response = await axios.get(`${backendAddress}/service/availability`, {});
            setServiceAvailable(response.data.serviceAvailable);
            setMessage(response.data.message);
            setIPV4Address(response.data.ipv4Address);
        } catch (error) {
            console.error('Service availability check error:', error);
        }
    };

    useEffect(() => {
        ServiceAvailabilityCheck();
    }, []);

    return { serviceAvailable, message, ipv4Address };
};