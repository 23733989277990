import React, { useState } from 'react';
import './css/bootstrap.min.css';
import './css/main.css';
import './css/now-ui-kit.css';
import './App.css';
import './css/profilesettings.css';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import AuditLogs from './Auditlog';

//userdata
import getUserData from './components/getUserData';

const ProfileSettings = () => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState('profile');

    const [nickname, email, mobile, pfpUrl, lvl, exp, expNeeded, status, userId, roleId, verified, roleBadgeComponent, renderVerifiedBadge] = getUserData();

    const renderContent = () => {
        switch (activeTab) {
            case 'profile':
                return <div>{t('Profile Content')}</div>;
            case 'auditlog':
                return <AuditLogs />;
            case 'notifications':
                return <div>{t('Notifications Content')}</div>;
            case 'confidentiality':
                return <div>{t('Confidentiality Content')}</div>;
            default:
                return <div>{t('Profile Content')}</div>;
        }
    };

    const toLogin = () => {
        window.location.href = '/login';
    }

    return (
        <div>
            {userId != null ? (
            <>
            <Helmet>
                <title>Settings | LimroNet</title>
            </Helmet>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-3 col-sm-12 sidebar'>
                        <ul className='nav flex-column nav-pills'>
                            <li className='nav-item'>
                                <span 
                                    className={`nav-link ${activeTab === 'profile' ? 'active' : ''}`} 
                                    onClick={() => setActiveTab('profile')}
                                >
                                    {t('Profile')}
                                </span>
                            </li>
                            <li className='nav-item'>
                                <span 
                                    className={`nav-link ${activeTab === 'auditlog' ? 'active' : ''}`} 
                                    onClick={() => setActiveTab('auditlog')}
                                >
                                    {t('Audit Log')}
                                </span>
                            </li>
                            <li className='nav-item'>
                                <span 
                                    className={`nav-link ${activeTab === 'notifications' ? 'active' : ''}`} 
                                    onClick={() => setActiveTab('notifications')}
                                >
                                    {t('Notifications')}
                                </span>
                            </li>
                            <li className='nav-item'>
                                <span 
                                    className={`nav-link ${activeTab === 'confidentiality' ? 'active' : ''}`} 
                                    onClick={() => setActiveTab('confidentiality')}
                                >
                                    {t('Confidentiality')}
                                </span>
                            </li>
                        </ul>
                    </div>
                    <div className='col-md-9 col-sm-12 content'>
                        {renderContent()}
                    </div>
                </div>
            </div>
            </>
            ) : (
                <>
                <div className='header3 notLoggedInContainer'>
                    <center>
                    <h1 className='display-5 title'>Want to see and manage your profile?</h1>
                    <p>Log in and get started!</p>
                    <a onClick={toLogin}>Login</a>
                    </center>
            </div>
                </>
            )}
        </div>
    );
};

export default ProfileSettings;