import { useState, useEffect } from "react";
import nopfp from '../assets/noserverpfp.png';
import DirectMessageEntry from "./homeDMEntry";

import "../css/home.css"

//example data
import directMessagesHome from "../exampleData/directMessagesHome";

const goToDM = () => {
    window.location.href = '/direct-messages';
}

const recentDMS = () => {
    return (
        <div className="direct-messages">
            <h1 className='display-5 title4'>Direct Messages</h1>
            {directMessagesHome.map((dm, index) => (
                <DirectMessageEntry
                    index={index}
                    sender={dm.username}
                    message={dm.lastMessage}
                    profilePicture={nopfp}
                    onClick={goToDM}
                />
            ))}
        </div>
    );
};

export default recentDMS;