import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';

import { useBackendAddress } from "./backendAddress";

export const useLoginManager = () => {

    const [loggedIn, setLoggedIn] = useState(false);

    const { backendAddress } = useBackendAddress();

    const logoutFunction = async () => {
        setLoggedIn(false);
        const sessionId = Cookies.get('session');
        Cookies.remove('session');
        if(sessionId){
            try {
                const response = await axios.post(`${backendAddress}/auth/session/delete`, {
                    sessionId
                });
            } catch (error) {
                console.error('Logout error:', error);
            }
        }
        //window.location.href = 'https://limro.top';
        window.location.href = 'http://localhost:3000';
        window.onload = function() {
            window.location.reload();
        };
    };

    const loggedInFunctionCheck = async () => {
        const sessionId = Cookies.get('session');
        if(!sessionId){
            setLoggedIn(false);
        } else {
            try {
                const response = await axios.post(`${backendAddress}/auth/session/check`, {
                    sessionId
                });
                if (response.status === 401) {
                    logoutFunction();
                } else if(response.status === 201 || response.data.message === 'sessionvalid'){
                    setLoggedIn(true);
                } else {
                    setLoggedIn(false);
                }
            } catch (error) {
                console.error('Session error:', error);
                setLoggedIn(false);
            }
        }
    };

    useEffect(() => {
        loggedInFunctionCheck();
    }, []);

    return {loggedIn, logoutFunction, setLoggedIn};
};