import React, { useState, useEffect } from 'react';
import './css/bootstrap.min.css';
import './css/main.css';
import './css/now-ui-kit.css';
import './App.css';
import './css/loginAndRegister.css';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import LimroLogo from './assets/fullsizelogo.png';
import axios from 'axios';
import Cookies from 'js-cookie';

import { useLoginManager } from './components/loginManager';

import { useBackendAddress } from './components/backendAddress';

const LoginPage = ({ showNavAndFoot, setShowNavAndFoot, showLogregFoot, setShowLogregFoot }) => {
    const { t } = useTranslation();
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [backendResponse, setBackendResponse] = useState('');

    const { backendAddress } = useBackendAddress();

    const { loggedIn, logoutFunction, setLoggedIn } = useLoginManager();

    setShowNavAndFoot(false);
    setShowLogregFoot(true);

    useEffect(() => {
        if (email.trim() && password.trim()) {
            setIsButtonDisabled(false);
        } else {
            setIsButtonDisabled(true);
        }
    }, [email, password]);

    const handleLogin = async () => {
        if (email === 'localhost' && password === 'localhost') {
            //localStorage.setItem('loginSession', 'localhost');
            window.location.href = '/login';
        } else {
            try {
                const response = await axios.post(`${backendAddress}/auth/login`, {
                    email,
                    password
                });
                console.log(response.data);
                if (response.status === 200) {
                    await newSession();
                    //window.location.href = 'https://limro.top';
                }
            } catch (error) {
                console.error('Error auth user:', error);
                if (error.response && error.response.data && error.response.data.message) {
                    setBackendResponse(error.response.data.message);
                } else {
                    setBackendResponse('An error occurred during login');
                }
            }
        }
    };

    const newSession = async () => {
        try {
            // const response = await axios.post('https://be.limro.top/auth/session/new', {
            const response = await axios.post(`${backendAddress}/auth/session/new`, {
                email
            });
            console.log(response.data);
            if (response.status === 201) {
                Cookies.set('session', response.data.sessionId, {
                    expires: new Date(Date.now() + 6 * 60 * 60 * 1000),
                    httpOnly: false,
                    secure: true,
                    sameSite: 'strict',
                });
                setLoggedIn(true);
                window.location.href = 'http://localhost:3000';
                //window.location.href = 'https://limro.top';
            }
        } catch (error) {
            console.error('Session error:', error);
            setBackendResponse('An error occurred during session creation');
        }
    };

    const handleKeyPress = (e) => {
        if(e.key === 'Enter' && !isButtonDisabled){
            handleLogin();
        }
    }

    useEffect(() => {
        if (loggedIn) {
            navigateToHome();
        }
    }, [loggedIn]);

    const navigateToHome = () => {
        window.location.href = '/';
    }

    return (
        <div className='fullscreen_container'>
            <Helmet>
                <title>Login | LimroNet</title>
            </Helmet>
            <div className="heading3">
                <h1 className='display-5 lr-title'>Continue your journey here at <img src={LimroLogo} className='lr_logo' alt='Limro Logo' /></h1>
                <div className='lr_container'>
                    <h1 className="display-5 lr-2-title">Limro[ID]</h1>
                    <h1 className="lr_inp_label">Email</h1>
                    <input type='email' className='input_lr' onChange={(e) => setEmail(e.target.value)} onKeyPress={handleKeyPress}/>
                    <h1 className="lr_inp_label">Password</h1>
                    <input type='password' className='input_lr' onChange={(p) => setPassword(p.target.value)} onKeyPress={handleKeyPress}/>
                    <div>
                        <button className="btn2" disabled={isButtonDisabled} onClick={handleLogin}>{t('auth_login')}</button>
                    </div>
                    {backendResponse !== '' && (
                        <div>
                            <h1 className="reg_notmatch_label">{backendResponse}</h1>
                        </div>
                    )}
                    <h1 className="reg_label">First time here?<a href="/register"><div><p>Register</p></div></a></h1>
                </div>
            </div>
        </div>
    );
};

export default LoginPage;