import { useState, useEffect } from "react";
import nopfp from '../assets/noserverpfp.png';
import axios from 'axios';
import Cookies from "js-cookie";
import "../css/main.css";
import '@fortawesome/fontawesome-free/css/all.min.css';

//notification
import { ToastContainer, toast } from 'react-toastify';

//userdata
import getUserData from './getUserData';
import RenderModeratorPanel from "./renderModeratorPanel";
import { useBackendAddress } from "./backendAddress";

const Post = ({ postId, postTitle, postContent, authorUserId, date }) => {
    const [authorName, setAuthorName] = useState(null);
    const [formattedDate, setFormattedDate] = useState(null);

    const [isExpanded, setIsExpanded] = useState(false);

    const [postLikeCount, setPostLikeCount] = useState(0);
    const [postCommentCount, setPostCommentCount] = useState(0);
    const [postShareCount, setPostShareCount] = useState(0);

    const [showModeratorPanel, setShowModeratorPanel] = useState(false);

    const { backendAddress } = useBackendAddress();

    const [nickname, email, mobile, pfpUrl, lvl, exp, expNeeded, status, userId, roleId, verified, roleBadgeComponent, renderVerifiedBadge] = getUserData();

    const toggleReadMore = (postId) => {
        setIsExpanded(!isExpanded);
        redirectToPostPage(postId);
    };

    const contentClass = isExpanded ? "post-content expanded" : "post-content";

    const redirectToAuthorProfile = (authorUserId) => {
        window.location.href = `https://limro.top/profile/${authorUserId}`;
        //window.location.href = `http://localhost:3000/profile/${authorUserId}`;
    }

    const redirectToPostPage = (postId) => {
        window.location.href = `http://localhost:3000/post/${postId}`;
    }

    const setCounts = async (postId) => {
        //     /data/post/get/counts
        try {
            const sessionId = Cookies.get('session');
            const response = await axios.get(`${backendAddress}/data/post/get/counts`, {
                params: {
                    postId: postId
                },
                headers: {
                    Authorization: `Bearer ${sessionId}`
                }
            });
            if (response.status === 200) {
                setPostLikeCount(response.data.likeCount);
                setPostCommentCount(response.data.commentCount);
                setPostShareCount(response.data.shareCount);
            }
        } catch (error) {
            console.error('Error setting like:', error);
        }
    }

    const setLike = async (userId, postId) => {
        try {
            const sessionId = Cookies.get('session');
            const response = await axios.post(`${backendAddress}/data/post/like/set`, {
                userId,
                postId
            }, {
                headers: {
                    Authorization: `Bearer ${sessionId}`
                }
            });
            if (response.status === 200) {
                //change like color and update count
                setPostLikeCount(response.data.likeCount);
            }
        } catch (error) {
            console.error('Error setting like:', error);
            toast.error('Error setting like. Please try again.', {
                theme: 'dark'
            });
        }
    }

    const makeShareLink = async (postId) => {
        try {
            const postLink = `https://limro.top/post/${postId}`;
            await navigator.clipboard.writeText(postLink);
            toast.success('Post link copied to clipboard!', {
                theme: 'dark'
            });
        } catch (error) {
            console.error('Failed to copy the link:', error);
            toast.error('Failed to copy post link. Please try again.', {
                theme: 'dark'
            });
        }

        try {
            const sessionId = Cookies.get('session');
            const response = await axios.post(`${backendAddress}/data/post/share/set`, {
                userId,
                postId
            }, {
                headers: {
                    Authorization: `Bearer ${sessionId}`
                }
            });
            if (response.status === 200) {
                //change like color and update count
                setPostShareCount(response.data.shareCount);
            }
        } catch (error) {
            console.error('Error setting like:', error);
        }
    }

    useEffect(() => {
        const getAuthorName = async () => {
            try {
                const sessionId = Cookies.get('session');
                const response = await axios.get(`${backendAddress}/data/post/author?userId=${authorUserId}`, {
                    headers: {
                        Authorization: `Bearer ${sessionId}`
                    }
                });
                setAuthorName(response.data.authorName);
            } catch (error) {
                console.error('Error fetching user data:',  error);
            }
        };

        getAuthorName();

        const formattedDate = formatDate(date);
        setFormattedDate(formattedDate);

        setCounts(postId);
    }, [date, authorUserId, postId]);

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' };
        const formattedDate = new Date(dateString).toLocaleDateString('en-US', options);
        return formattedDate;
    }

    const onModClick = () => {
        if(showModeratorPanel){
            setShowModeratorPanel(false);
        } else {  
            setShowModeratorPanel(true);
        }
    }

    return (
        <div className="post">
            {showModeratorPanel && (
                <RenderModeratorPanel postId={postId} />
            )}
            <img src={nopfp} className="post-pfp" alt="Profile Picture" />
            <div className={contentClass}>
                <h2 onClick={() => redirectToPostPage(postId)}>{postTitle}</h2>
                <p onClick={() => redirectToPostPage(postId)}>{postContent}</p>
                {!isExpanded && <div className="read-more-overlay"></div>}
                <a onClick={(e) => redirectToAuthorProfile(authorUserId)}>Author: {authorName}</a>
                <p className="title10">{formattedDate}</p>
                <div className="postControls">
                    <p>
                        <i className="fas fa-heart controlColor" onClick={() => setLike(userId, postId)}></i>
                        <span className="sr-only">(current)</span> {postLikeCount}
                    </p>
                    <p>
                        <i className="fas fa-comment controlColor" onClick={() => redirectToPostPage(postId)}></i>
                        <span className="sr-only">(current)</span> {postCommentCount}
                    </p>
                    <p>
                        <i className="fas fa-share controlColor" onClick={() => makeShareLink(postId)}></i>
                        <span className="sr-only">(current)</span> {postShareCount}
                    </p>
                </div>
                {!isExpanded && <span className="read-more" onClick={() => toggleReadMore(postId)}>Read More</span>}
            </div>
        </div>
    );
};

export default Post;