const directMessagesHome = [
    {
        userId: 7,
        username: 'Alice',
        lastMessage: 'Hiii',
    },
    {
        userId: 4,
        username: 'KeyShark',
        lastMessage: 'bratik go tft 1 match?',
    },
    {
        userId: 3,
        username: 'Rihards ryr',
        lastMessage: 'Izspēlē cwl!!!!!!!!!',
    },
    {
        userId: 2,
        username: 'Daniels KeyBearboO',
        lastMessage: 'Tapec fake',
    },
    {
        userId: 8,
        username: 'Arturs',
        lastMessage: 'ievadi paroli ludzu',
    },
]

export default directMessagesHome;