import React, { useEffect, useState, useRef } from 'react';
import { Routes, Route, Link, useLocation, useNavigate } from 'react-router-dom';
import logo from './assets/fullsizelogo.png';
import menuIcon from './assets/menuIcon.svg';
import { useTranslation } from 'react-i18next';
import i18n from './i18n';
import './css/system-messages.css';
import Cookies from 'js-cookie';
import nopfp from './assets/noserverpfp.png';
import SystemNotification from './SystemNotification'
//import { isLoggedIn } from './SetLoginStateUtil';
import CryptoJS from 'crypto-js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LimroStudios from './assets/LimroStudiosLogo.png';
import './css/footer.css';
import { faTwitter, faDiscord, faItchIo, faYoutube, faPatreon, faWindows } from '@fortawesome/free-brands-svg-icons';
import CustomScrollbar from './customscrollbar.jsx';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import axios from 'axios';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './css/dashboard.css'

//notification
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//components
import { useLoginManager } from './components/loginManager';
import getUserData from './components/getUserData';
import { useServiceAvailability } from './components/serviceAvailability';
import { useBackendAddress } from './components/backendAddress';

//pages
import About from './About';
import Home from './Home';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfUse from './TermsOfUse'
import NotFound from './NotFound';
import LoginError from './LoginError';
import Auditlog from './Auditlog';
import Profile from './Profile';
import DirectMessages from './DirectMessages';
import GDPRPrivacyPolicy from './GDPRPolicy';
import LoginPage from './LoginPage.js';
import RegisterPage from './RegisterPage.js';
import ProfileSettings from './ProfileSettings';
import PostPage from './PostPage';
import ServiceUnavailable from './ServiceUnavailable';

//emojis
import us from './assets/us.png'
import ru from './assets/ru.png'
import cn from './assets/cnzh.png'
import jp from './assets/jp.png'
import ko from './assets/ko.png'
import { use } from 'i18next';

const getMessageStyle = (msgPriority) => {
  switch (msgPriority) {
    case 1:
      return 'system-message-green';
    case 2:
      return 'system-message-yellow';
    case 3:
      return 'system-message-red';
    default:
      return 'system-message-gray';
  }
};

const App = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const [systemMessages, setSystemMessages] = useState([]);
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const navbarRef = useRef(null);
  const [acceptedCookies, setAcceptedCookies] = useState(false);
  const currentYear = new Date().getFullYear();
  const [showNavAndFoot, setShowNavAndFoot] = useState(false);
  const [showLogregFoot, setShowLogregFoot]= useState(true);

  //loggedIn
  const {loggedIn, logoutFunction} = useLoginManager();

  //userData
  const [nickname, email, mobile, pfpUrl, lvl, exp, expNeeded, status, userId, roleId, verified, roleBadgeComponent, renderVerifiedBadge] = getUserData();

  //serviceAvailability
  const { serviceAvailable, message, ipv4Address } = useServiceAvailability();
  const { backendAddress } = useBackendAddress();

  useEffect(() => {
    enableFoot();
  }, [loggedIn]);

  const enableFoot = () => {
      if(loggedIn){
          setShowNavAndFoot(true);
          setShowLogregFoot(false);
      } else {
          setShowNavAndFoot(false);
          setShowLogregFoot(true);
      }
  };

  const handleNavToggle = () => {
    setIsNavCollapsed(!isNavCollapsed);
  };

  const handleOtherButtonClick = () => {
    setIsNavCollapsed(true);
  };

  const handleAcceptCookies = () => {
    setAcceptedCookies(true);
    Cookies.set('acceptedCookies', true, {
      expires: 365,
      httpOnly: false,
      secure: true,
      sameSite: 'strict',
    });
  };

  useEffect(() => {
    const deleteExpiredCookies = () => {
      const allCookies = Cookies.get();

      Object.keys(allCookies).forEach((cookieName) => {
        const expirationDate = new Date(Cookies.get(cookieName, { raw: true }));

        if (expirationDate < new Date()) {
          Cookies.remove(cookieName, { domain: '.limro.top' });
        }
      });
    };

    deleteExpiredCookies();

    const acceptedCookies = Cookies.get('acceptedCookies');

    if (acceptedCookies === undefined || acceptedCookies === 'false') {
      setAcceptedCookies(false);
    } else {
      setAcceptedCookies(true);
    }

    const handleClickOutside = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setIsNavCollapsed(true);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);


  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const handleLogout = async () => {
    if(localStorage.getItem('loginSession')){
      localStorage.removeItem('loginSession');
      window.location.href = '/';
    } else {
      await logoutFunction();
    }
  };

  const goToAbout = () => {
    window.location.href = '/about'
  }

  const handleLogin = async () => {
    window.location.href = '/login';
  };

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    localStorage.setItem('lang', language);
  };

const navigateToProfile = () => {
  window.location.href = '/profile';
};

const navigateToSettings = () => {
  window.location.href = '/profile/settings';
};

  useEffect(() => {
    const lang = localStorage.getItem('lang');
    let languageToSet;
    if (lang) {
      i18n.changeLanguage(lang);
    } else {
      const browserLanguage = navigator.language || navigator.userLanguage;
      const defaultLanguage = browserLanguage.substr(0, 2);

      if (defaultLanguage === 'zh') {
        languageToSet = 'zh-CN';
      } else if (defaultLanguage === 'ko') {
        languageToSet = 'ko-KR';
      } else if (defaultLanguage === 'jp') {
        languageToSet = 'jp-JP';
      } else {
        languageToSet = ['en', 'ru', 'zh-CN', 'jp-JP', 'ko-KR'].includes(defaultLanguage) ? defaultLanguage : 'en';
      }

      localStorage.setItem('lang', languageToSet);
    }
    
    const fetchSystemMessages = () => {
      fetch(`${backendAddress}/api/system-messages`)
        .then((response) => response.json())
        .then((data) => {
          // Check if the new messages are different from the existing state
          if (JSON.stringify(data) !== JSON.stringify(systemMessages)) {
            setSystemMessages(data);
          }
        })
        .catch((error) => console.error('Error fetching system messages:', error));
    };
    fetchSystemMessages();
    const fetchInterval = setInterval(fetchSystemMessages, 3000);
    return () => {
      clearInterval(fetchInterval);
    };
  }, [systemMessages, i18n]);

  return (
    <div className='custom-scrollbar-wrapper'>
      <SimpleBar className='simplebar_css' style={{ paddingRight: '0px', overflowX: 'hidden' }} autoHide={false}>
        <div>
        { showNavAndFoot &&
          <nav className={'navbar navbar-expand-lg'} ref={navbarRef}>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded={!isNavCollapsed}
            aria-label="Toggle navigation"
            onClick={handleNavToggle}
          >
            <img src={menuIcon} width="20" height="20" alt="Menu Icon" style={{ maxWidth: 'none !important' }} />
          </button>
          <Link to="/">
              <img
                  src={logo}
                  width="110"
                  height="30"
                  style={{ marginRight: "20px" }}
                  alt="Logo"
              />
          </Link>
            <div className="collapse navbar-collapse" id="navbarSupportedContent" style={{ marginLeft: '20px !important' }}>
              <ul className="navbar-nav mr-auto">
                <li className="nav-item active">
                    <Link to="/" className="nav-link">
                        <i className="fas fa-home"></i><span className="sr-only">(current)</span>
                    </Link>
                </li>
                <li className="nav-item active">
                    <Link to="/direct-messages" className="nav-link">
                        <i className="fas fa-envelope"></i><span className="sr-only">(current)</span>
                    </Link>
                </li>
              </ul>
                          <div className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  {/*{<img src={globus} width="20" height="20" style={{ marginRight: '2px' }} />*/}
                  <svg fill="#ffff" width="20px" height="20px" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><title>ionicons-v5-l</title><path d="M478.33,433.6l-90-218a22,22,0,0,0-40.67,0l-90,218a22,22,0,1,0,40.67,16.79L316.66,406H419.33l18.33,44.39A22,22,0,0,0,458,464a22,22,0,0,0,20.32-30.4ZM334.83,362,368,281.65,401.17,362Z"/><path d="M267.84,342.92a22,22,0,0,0-4.89-30.7c-.2-.15-15-11.13-36.49-34.73,39.65-53.68,62.11-114.75,71.27-143.49H330a22,22,0,0,0,0-44H214V70a22,22,0,0,0-44,0V90H54a22,22,0,0,0,0,44H251.25c-9.52,26.95-27.05,69.5-53.79,108.36-31.41-41.68-43.08-68.65-43.17-68.87a22,22,0,0,0-40.58,17c.58,1.38,14.55,34.23,52.86,83.93.92,1.19,1.83,2.35,2.74,3.51-39.24,44.35-77.74,71.86-93.85,80.74a22,22,0,1,0,21.07,38.63c2.16-1.18,48.6-26.89,101.63-85.59,22.52,24.08,38,35.44,38.93,36.1a22,22,0,0,0,30.75-4.9Z"/></svg>
                  {/*{t('nav_language')}*/}
                </a>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <button className="dropdown-item" onClick={() => changeLanguage('en')}>
                  <img src={us} alt="us" width="16" height="12" style={{ marginRight: '2px' }} />
                  English
                  </button>
                  <div className="dropdown-divider"></div>
                  <button className="dropdown-item" onClick={() => changeLanguage('ru')}>
                  <img src={ru} alt="ru" width="16" height="12" style={{ marginRight: '2px' }} />
                  Русский
                  </button>
                  <div className="dropdown-divider"></div>
                  <button className="dropdown-item" onClick={() => changeLanguage('cn')}>
                  <img src={cn} alt="cn" width="16" height="12" style={{ marginRight: '2px' }} />
                  简体中文
                  </button>
                  <div className="dropdown-divider"></div>
                  <button className="dropdown-item" onClick={() => changeLanguage('jp')}>
                  <img src={jp} alt="jp" width="16" height="12" style={{ marginRight: '2px' }} />
                  日本語
                  </button>
                  <div className="dropdown-divider"></div>
                  <button className="dropdown-item" onClick={() => changeLanguage('ko')}>
                  <img src={ko} alt="ko" width="16" height="12" style={{ marginRight: '2px' }} />
                  한국어
                  </button>
                </div>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <button className="dropdown-item" onClick={() => changeLanguage('en')}>
                  <img src={us} alt="us" width="16" height="12" style={{ marginRight: '2px' }} />
                  English
                  </button>
                </div>
              </div>

              {loggedIn ? (
          <div className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <img
                src={nopfp}
                alt="User Profile"
                className="hover-img"
                style={{
                  width: '30px',
                  height: '30px',
                  borderRadius: '30%',
                  marginRight: '5px',
                }}
                onError={(e) => {
                  e.target.src = nopfp;
                }}
              />
              {nickname} {renderVerifiedBadge()}
            </a>
            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
              <button className="dropdown-item" onClick={navigateToProfile}>
              <i className="fas fa-user"></i><span className="sr-only">(current)</span> {t('profile_profile')}
              </button>
              <div className="dropdown-divider"></div>
              <button className="dropdown-item" onClick={navigateToSettings}>
                <i className="fas fa-gear"></i><span className="sr-only">(current)</span> Settings
              </button>
              <div className="dropdown-divider"></div>
              <button className="dropdown-item" onClick={handleLogout}>
                <i className="fas fa-right-from-bracket"></i><span className="sr-only">(current)</span> {t('auth_logout')}
              </button>
            </div>
          </div>
        ) : (
          <button
            className="btn2 login-btn btn-outline-accent my-2 my-sm-0"
            onClick={handleLogin}
            style={{ fontSize: '10px !important', fontFamily: 'poppins !important' }}
          >
            {t('auth_login')}
          </button>
        )}
            </div>

          </nav>
        }

        {systemMessages.length > 0 && (
          <div className="system-messages-container">
            {systemMessages.map((message, index) => (
              <div
                key={index}
                className={`system-message ${getMessageStyle(message.msg_priority)}`}
              >
                {message.message}
              </div>
            ))}
          </div>
        )}
        
        <Routes>
          {!serviceAvailable ? (
            <>
            <Route path="*" element={<ServiceUnavailable serviceAvailable={serviceAvailable} message={message} ipv4Address={ipv4Address} />} />
            </>
          ) : (
            <>
            <Route path="/" element={<Home showNavAndFoot={showNavAndFoot} setShowNavAndFoot={setShowNavAndFoot} showLogregFoot={showLogregFoot} setShowLogregFoot={setShowLogregFoot} />} />
            <Route path="/about" element={<About />} />
            <Route path="*" element={<NotFound />} />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route path="/gdprprivacypolicy" element={<GDPRPrivacyPolicy />} />
            <Route path="/termsofuse" element={<TermsOfUse />} />
            <Route path="/loginerror" element={<LoginError />} />
            <Route path="/profile" element={<Profile showNavAndFoot={showNavAndFoot} setShowNavAndFoot={setShowNavAndFoot} showLogregFoot={showLogregFoot} setShowLogregFoot={setShowLogregFoot} />} />
            <Route path="/direct-messages" element={<DirectMessages />} />
            <Route path="/login" element={<LoginPage showNavAndFoot={showNavAndFoot} setShowNavAndFoot={setShowNavAndFoot} showLogregFoot={showLogregFoot} setShowLogregFoot={setShowLogregFoot} />} />
            <Route path="/register" element={<RegisterPage showNavAndFoot={showNavAndFoot} setShowNavAndFoot={setShowNavAndFoot} showLogregFoot={showLogregFoot} setShowLogregFoot={setShowLogregFoot} />} />
            <Route path="/profile/settings/*" element={<ProfileSettings/>} />
            <Route path="/post/:postId" element={<PostPage/>} />
            </>
          )}
        </Routes>

        <ToastContainer position="bottom-right" theme="dark"/>
        
        {!acceptedCookies && (
          <div className="accept-cookies-container">
            <button className="close-cookies-button" onClick={handleAcceptCookies}>
              X
            </button>
            <div className="accept-cookies-content">
              <p>
                {t('acceptcookies')}
              </p>
            </div>
          </div>
        )}

        {showNavAndFoot &&
          <div className="footer2">
              <div className="sb_footer section_padding">
                <div className='sb_footer-links'>
                  <div className='sb_footer-links-div'>
                    <h4>{t('ft_subsciptions')}</h4>
                    <a href="/subscriptions">
                      <p>{t('nav_subscription')}</p>
                    </a>
                  </div>
                  <div className='sb_footer-links-div'>
                    <h4>{t('ft_docs')}</h4>
                      <a href="https://limro-studios.gitbook.io/limro-sama-docs/limro-sama-commands/standart-commands">
                        <p>{t('ft_cmdls')}</p>
                      </a>
                      <a href="https://limro-studios.gitbook.io/limro-sama-docs/setting-up-limro-sama/setting-up-suggestions-channel">
                        <p>{t('ft_setup')}</p>
                      </a>
                  </div>
                  <div className='sb_footer-links-div'>
                    <h4>{t('ft_partners')}</h4>
                      <a href="/about">
                        <p>{t('ft_credits')}</p>
                      </a>
                  </div>
                  <div className='sb_footer-links-div'>
                    <h4>{t('ft_otherli')}</h4>
                      <a href='https://limro.top'>
                        <p>Limro Studios</p>
                      </a>
                      <a href="https://bot.limro.top/">
                        <p>Limro Bot </p>
                      </a>
                      <a href="mailto:limrostudios@limro.top">
                        <p>{t('ft_supemail')}</p>
                      </a>
                      <a onClick={goToAbout}>
                        <p>About</p>
                      </a>
                  </div>
                  <div className='sb_footer-links-div'>
                    <h4>{t('ft_socialmedia')}:</h4>
                      <a href="https://twitter.com/LimroStudios" target="_blank" rel="noopener noreferrer" className="social-link">
                        <FontAwesomeIcon icon={faTwitter} />
                      </a>
                      <a href="https://discord.gg/55WjVBMDvH" target="_blank" rel="noopener noreferrer" className="social-link">
                        <FontAwesomeIcon icon={faDiscord} />
                      </a>
                      <a href="https://limro-studios.itch.io/" target="_blank" rel="noopener noreferrer" className="social-link">
                        <FontAwesomeIcon icon={faItchIo} />
                      </a>
                      <a href="https://www.youtube.com/channel/UC-V5fPOQfFtIQLAGwJA-a3g" target="_blank" rel="noopener noreferrer" className="social-link">
                        <FontAwesomeIcon icon={faYoutube} />
                      </a>
                  </div>
                </div>
              <hr></hr>
              <div className='sb_footer-below'>
                <div className='sb_footer-copyright'>
                  <p>{t('foot_copyright')} © Limro Studios | {currentYear}</p>
                </div>
                {/*<img src={LimroStudios} alt="Limro Studios" className='imge' />
                <img src={logo} alt="LimroBot" className='imge2' />*/}
                <div className='sb_footer-below-links'>
                  <a href="/gdprprivacypolicy"><div><p>{t('foot_gdprprivacypolicy')}</p></div></a>
                  <a href="/privacypolicy"><div><p>{t('foot_privacypolicy')}</p></div></a>
                  <a href="/termsofuse"><div><p>{t('foot_termsofuse')}</p></div></a>
                </div>
              </div>
              </div>
          </div>
        }

      {showLogregFoot &&
        <div className='logRegNavContainer'>
          <div className='sb_footer-below'>
            <div className='sb_footer-copyright'>
              <p>{t('foot_copyright')} © Limro Studios | {currentYear}</p>
            </div>
            <div className='sb_footer-below-links'>
              <div className="nav-item dropup">
                <a className="nav-link dropdown-toggle dropdown-item-text" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {t('nav_language')}
                </a>
                <div className="dropdown-menu dropup" aria-labelledby="navbarDropdown">
                  <button className="dropdown-item" onClick={() => changeLanguage('en')}>
                    <img src={us} alt="us" width="16" height="12" style={{ marginRight: '2px' }} />
                    English
                  </button>
                  <div className="dropdown-divider"></div>
                  <button className="dropdown-item" onClick={() => changeLanguage('ru')}>
                    <img src={ru} alt="ru" width="16" height="12" style={{ marginRight: '2px' }} />
                    Русский
                  </button>
                  <div className="dropdown-divider"></div>
                  <button className="dropdown-item" onClick={() => changeLanguage('cn')}>
                    <img src={cn} alt="cn" width="16" height="12" style={{ marginRight: '2px' }} />
                    简体中文
                  </button>
                  <div className="dropdown-divider"></div>
                  <button className="dropdown-item" onClick={() => changeLanguage('jp')}>
                    <img src={jp} alt="jp" width="16" height="12" style={{ marginRight: '2px' }} />
                    日本語
                  </button>
                  <div className="dropdown-divider"></div>
                  <button className="dropdown-item" onClick={() => changeLanguage('ko')}>
                    <img src={ko} alt="ko" width="16" height="12" style={{ marginRight: '2px' }} />
                    한국어
                  </button>
                </div>
              </div>
              <a href="/gdprprivacypolicy"><p>{t('foot_gdprprivacypolicy')}</p></a>
              <a href="/privacypolicy"><p>{t('foot_privacypolicy')}</p></a>
              <a href="/termsofuse"><p>{t('foot_termsofuse')}</p></a>
            </div>
          </div>
        </div>
      }
      </div>
    </SimpleBar>
    </div>
  );
};

export default App;