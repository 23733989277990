import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import './css/bootstrap.min.css';
import './css/main.css';
import './css/count-anim.css';
import './css/now-ui-kit.css';
import './App.css';
import './css/home.css';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet'
import favicon from './assets/favicon.ico';
import Counter from "./Counter";
import CountUp from "react-countup";
import { useLoginManager } from './components/loginManager';
import nopfp from './assets/noserverpfp.png';
import DirectMessageEntry from './components/homeDMEntry';
import Cookies from 'js-cookie';

//posts test
import RenderFYP from './components/getNRenderPosts';

//recent dms
import recentDMS from './components/recentDMS';

//userdata
import getUserData from './components/getUserData';

import { useBackendAddress } from './components/backendAddress';

const Home = ({ showNavAndFoot, setShowNavAndFoot, showLogregFoot, setShowLogregFoot }) => {
    const { t } = useTranslation();
    const {loggedIn, logoutFunction} = useLoginManager();

    const [nickname, email, mobile, pfpUrl, lvl, exp, expNeeded, status, userId, roleId, verified, roleBadgeComponent, renderVerifiedBadge] = getUserData();

    const [newPost, setNewPost] = useState('');
    const [postTitle, setNewPostTitle] = useState('');

    const { backendAddress } = useBackendAddress();

    const handleNewPostChange = (e) => {
        setNewPost(e.target.value);
    };

    const handleNewPostTitleChange = (e) => {
        setNewPostTitle(e.target.value);
    };

    const handleSendPost = () => {
        const postContent = newPost.replace(/(?:\r\n|\r|\n)/g, '\\n');
        const sessionId = Cookies.get('session');
        fetch(`${backendAddress}/data/post/new`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${sessionId}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ postTitle: postTitle, content: postContent, senderId: userId }),
        })
        .then(response => {
            if (response.ok) {
                setNewPost('');
                setNewPostTitle('');
                window.location.reload();
            } else {
                console.error('Failed to send post:', response.statusText);
            }
        })
        .catch(error => {
            console.error('Error sending post:', error);
        });
    };

    const textareaRef = useRef(null);

    useEffect(() => {
        autoResizeTextarea();
    }, [newPost]);

    const autoResizeTextarea = () => {
        if (textareaRef.current) {
            const textarea = textareaRef.current;
            textarea.style.height = 'auto';
            textarea.style.height = `${textarea.scrollHeight}px`;
        }
    };

    const navigateToLogin = () => {
        window.location.href = '/login';
    };

    const navigateToRegister = () => {
        window.location.href = '/register'
    };
    
    return (
        <div className="heading">
            <Helmet>
                <title>LimroNet</title>
            </Helmet>
            {!loggedIn ? (
                <>
                <div className='start_container'>
                    <h1 className="display-5 title">Discover interesting with LimroNet</h1>
                    <p className="title4">Join and check it now<br /></p>
                    <div className='btncontainer'>
                        <a className="btn btn-primary btn-lg" onClick={navigateToLogin} target="_blank" rel="noopener noreferrer" role="button">
                            Login
                        </a>
                        <a className="btn btn-secondary btn-lg" onClick={navigateToRegister} target="_blank" rel="noopener noreferrer" role="button">
                            Register
                        </a>
                    </div>
                </div>
                </>
            ) : (
                <>
                        <div className="containerhome">
                            <div className="user-posts">
                                <div className='main-post-sender'>
                                    <div className='inputs'>
                                        <input 
                                            type="text"
                                            ref={textareaRef}
                                            placeholder="Write a title..."
                                            className='main-post-sender-input'
                                            value={postTitle}
                                            onChange={handleNewPostTitleChange}
                                        />
                                        <textarea 
                                            type="text"
                                            ref={textareaRef}
                                            placeholder="Write a new post..."
                                            className='main-post-sender-textblock'
                                            value={newPost}
                                            onChange={handleNewPostChange}
                                        />
                                    </div>
                                    <button 
                                        className="btn btn-primary" 
                                        onClick={handleSendPost}
                                    >
                                        <i className="fas fa-paper-plane"></i><span className="sr-only">(current)</span> Send
                                    </button>
                                </div>
                                <RenderFYP />
                            </div>
                            <div className="recent-dms-container">
                                {recentDMS()}
                            </div>
                        </div>
                </>
            )}
        </div>
    );
};

export default Home;