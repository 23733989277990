import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import './css/post-page.css'

//notification
import { ToastContainer, toast } from 'react-toastify';

//userdata
import getUserData from './components/getUserData';

import RenderModeratorPanel from "./components/renderModeratorPanel";

//new lines
import RenderFormattedMessage from './components/formatPostMessage';

import { useBackendAddress } from './components/backendAddress';

const PostPage = () => {
    const { postId } = useParams();
    const [post, setPost] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [authorName, setAuthorName] = useState(null);
    const [authorId, setAuthorId] = useState(null);
    const [formattedDate, setFormattedDate] = useState(null);

    const [postLikeCount, setPostLikeCount] = useState(0);
    const [postCommentCount, setPostCommentCount] = useState(0);
    const [postShareCount, setPostShareCount] = useState(0);

    const [showModeratorPanel, setShowModeratorPanel] = useState(false);

    const [postDeleted, setPostDeleted] = useState(false);
    const [deletedTimeStamp, setDeletedTimestamp] = useState(null);
    const [deletedByAuthor, setDeletedByAuthor] = useState(null);

    const [commentMessage, setCommentMessage] = useState('');

    const { backendAddress } = useBackendAddress();

    const [nickname, email, mobile, pfpUrl, lvl, exp, expNeeded, status, userId, roleId, verified, roleBadgeComponent, renderVerifiedBadge] = getUserData();

    const setLike = async (userId, postId) => {
        try {
            const sessionId = Cookies.get('session');
            const response = await axios.post(`${backendAddress}/data/post/like/set`, {
                userId,
                postId
            }, {
                headers: {
                    Authorization: `Bearer ${sessionId}`
                }
            });
            if (response.status === 200) {
                //change like color and update count
                setPostLikeCount(response.data.likeCount);
            }
        } catch (error) {
            console.error('Error setting like:', error);
            toast.error('Error setting like. Please try again.', {
                theme: 'dark'
            });
        }
    }

    const makeShareLink = async (postId) => {
        try {
            const postLink = `https://limro.top/post/${postId}`;
            await navigator.clipboard.writeText(postLink);
            toast.success('Post link copied to clipboard!', {
                theme: 'dark'
            });
        } catch (error) {
            console.error('Failed to copy the link:', error);
            toast.error('Failed to copy post link. Please try again.', {
                theme: 'dark'
            });
        }

        try {
            const sessionId = Cookies.get('session');
            const response = await axios.post(`${backendAddress}/data/post/share/set`, {
                userId,
                postId
            }, {
                headers: {
                    Authorization: `Bearer ${sessionId}`
                }
            });
            if (response.status === 200) {
                //change like color and update count
                setPostShareCount(response.data.shareCount);
            }
        } catch (error) {
            console.error('Error setting like:', error);
        }
    }

    const setCounts = async (postId) => {
        //     /data/post/get/counts
        try {
            const sessionId = Cookies.get('session');
            const response = await axios.get(`${backendAddress}/data/post/get/counts`, {
                params: {
                    postId: postId
                },
                headers: {
                    Authorization: `Bearer ${sessionId}`
                }
            });
            if (response.status === 200) {
                setPostLikeCount(response.data.likeCount);
                setPostCommentCount(response.data.commentCount);
                setPostShareCount(response.data.shareCount);
            }
        } catch (error) {
            console.error('Error setting like:', error);
        }
    }

    useEffect(() => {
        const fetchPost = async () => {
            try {
                const sessionId = Cookies.get('session');
                const response = await axios.get(`${backendAddress}/data/post/get?postId=${postId}`, {
                    headers: {
                        Authorization: `Bearer ${sessionId}`
                    }
                });
                const postData = response.data;
                setPost(postData);
                setAuthorId(postData.data.postSenderId);
                if(postData.data.deleted === 1){
                    setPostDeleted(true);
                    const deletedTime = formatDate(postData.data.deletedTimeStamp);
                    setDeletedTimestamp(deletedTime);
                }
            } catch (err) {
                setError('Failed to fetch post content');
            } finally {
                setLoading(false);
            }
        };
    
        fetchPost();
    }, [postId]);
    
    useEffect(() => {
        if (post) {
            const getAuthorName = async () => {
                try {
                    const sessionId = Cookies.get('session');
                    const response = await axios.get(`${backendAddress}/data/post/author?userId=${post.data.postSenderId}`, {
                        headers: {
                            Authorization: `Bearer ${sessionId}`
                        }
                    });
                    setAuthorName(response.data.authorName);
                } catch (error) {
                    console.error('Error fetching author data:', error);
                }
            };
    
            getAuthorName();
    
            const formattedDate = formatDate(post.data.postTimeStamp);
            setFormattedDate(formattedDate);
    
            setCounts(postId);
        }
    }, [post]);

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' };
        const formattedDate = new Date(dateString).toLocaleDateString('en-US', options);
        return formattedDate;
    }

    const onModClick = () => {
        if(showModeratorPanel){
            setShowModeratorPanel(false);
        } else {  
            setShowModeratorPanel(true);
        }
    }

    const onEditClick = () => {

    }

    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    const onDeleteClick = () => {
        setShowDeleteDialog(true);
    }

    const [reason, setReason] = useState('');

    const handleReasonChange = (event) => {
      setReason(event.target.value);
    };

    const onDeleteConfirm = async () => {
        try {
            const sessionId = Cookies.get('session');
            const response = await axios.post(`${backendAddress}/data/post/delete`, {
                userId,
                postId,
                reason
            }, {
                headers: {
                    Authorization: `Bearer ${sessionId}`
                }
            });
            if (response.status === 200) {
                setShowDeleteDialog(false);
                window.location.reload();
            }
        } catch (error) {
            console.error('Error deleting post:', error);
        }
      }

    const onCancelClick = () => {
        setReason('');
        setShowDeleteDialog(false);
    }

    const toLogin = () => {
        window.location.href = '/login';
    }

    useEffect(() => {
        const getAuthorName = async () => {
            try {
                const sessionId = Cookies.get('session');
                const response = await axios.get(`${backendAddress}/data/post/author?userId=${post.data.deletedById}`, {
                    headers: {
                        Authorization: `Bearer ${sessionId}`
                    }
                });
                setDeletedByAuthor(response.data.authorName);
            } catch (error) {
                console.error('Error fetching user data:',  error);
            }
        };

        getAuthorName();
    }, [post]);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    return (
        <>
        {userId != null ? (
            <>
                <div className="header3 post-page">
                    {showModeratorPanel && (
                        <RenderModeratorPanel postId={postId} handleDelete={onDeleteClick} />
                    )}
                    {!postDeleted ? (
                        <>
                            <h1 className="display-5 title">{post.data.postTitle}</h1>
                            <hr />
                            <RenderFormattedMessage message={post.data.postMessage} />
                            <hr />
                            <p className="post-info">Author: {authorName}</p>
                            <p className="post-info">{formattedDate}</p>
                            <div className="postControls">
                                <p>
                                    <i className="fas fa-heart controlColor" onClick={() => setLike(userId, postId)}></i>
                                    <span className="sr-only">(current)</span> {postLikeCount}
                                </p>
                                <p>
                                    <i className="fas fa-comment controlColor"></i>
                                    <span className="sr-only">(current)</span> {postCommentCount}
                                </p>
                                <p>
                                    <i className="fas fa-share controlColor" onClick={() => makeShareLink(postId)}></i>
                                    <span className="sr-only">(current)</span> {postShareCount}
                                </p>
                                <p className="naControl">|</p>
                                {roleId > 0 && (
                                    <p>
                                        <i className="fas fa-building-shield controlColor" onClick={onModClick}></i>
                                        <span onClick={onModClick}> Moderate</span>
                                    </p>
                                )}
                                {userId === post.data.postSenderId && (
                                    <>
                                        <p>
                                            <i className="fas fa-pen controlColor" onClick={onEditClick}></i>
                                            <span onClick={onEditClick}> Edit</span>
                                        </p>
                                        <p>
                                            <i className="fas fa-trash controlColor" onClick={onDeleteClick}></i>
                                            <span onClick={onDeleteClick}> Delete</span>
                                        </p>
                                    </>
                                )}
                            </div>
                        </>
                    ) : (
                        <>
                            <h1 className="display-5 title">Post deleted!</h1>
                            {post.data.deletedById === post.data.postSenderId ? (
                                <p>Deleted by: Post author</p>
                            ) : (
                                <>
                                    <p>Deleted by moderator: <a className='deletedModProfile' onClick={() => window.location.href = `/profile/${post.data.deletedById}`}>{deletedByAuthor}</a></p>
                                    <p>Reason: {post.data.deletedReason}</p>
                                </>
                            )}
                            <p>Deleting time: {formatDate(post.data.deletedTimeStamp)}</p>
                        </>
                    )}
                    {showDeleteDialog && (
                        <div className="delete-dialog-overlay">
                            <div className="delete-dialog">
                                <div className="delete-dialog-content">
                                    <p>Are you sure you want to delete this post?</p>
                                    <p>Enter reason why you would like to delete this post.</p>
                                    <input
                                        value={reason}
                                        onChange={handleReasonChange}
                                        placeholder="Deleting reason"
                                        className="main-post-sender-input"
                                    />
                                    <br />
                                    <br />
                                    <div className="delete-dialog-buttons">
                                        <button onClick={onDeleteConfirm}>Delete</button>
                                        <button onClick={onCancelClick}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </>
        ) : (
            <>
            <div className='header3 notLoggedInContainer'>
                <center>
                <h1 className='display-5 title'>Want to see posts?</h1>
                <p>Log in and let's explore them together!</p>
                <a onClick={toLogin}>Login</a>
                </center>
            </div>
            </>
        )}
        </>
    );
};

export default PostPage;