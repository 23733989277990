import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslations from './locales/en.json';
import ruTranslations from './locales/ru.json';
import zhCNTranslations from './locales/zh-CN.json';
import jpJPTranslations from './locales/jp-JP.json';
import koKRTranslations from './locales/ko-KR.json';

const resources = {
  en: { translation: enTranslations },
  ru: { translation: ruTranslations },
  cn: { translation: zhCNTranslations },
  jp: { translation: jpJPTranslations },
  ko: { translation: koKRTranslations },
};

const getBrowserLanguage = () => {
  const userLanguage = navigator.language || navigator.userLanguage;
  return userLanguage.split('-')[0];
};

const userLanguage = getBrowserLanguage();
const defaultLanguage = 'en';

i18n.use(initReactI18next).init({
  resources,
  lng: resources[userLanguage] ? userLanguage : defaultLanguage,
  fallbackLng: defaultLanguage,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;