import React from 'react';

const RenderFormattedMessage = ({ message }) => {
  return (
    <p className='post-message' style={{ whiteSpace: 'pre-line !important' }}>
      {message.split('\n').map((line, index) => (
        <React.Fragment key={index}>
          {line}
          <br />
        </React.Fragment>
      ))}
    </p>
  );
};

export default RenderFormattedMessage;