import React, { useState, useEffect } from "react"; // Import React
import nopfp from '../assets/noserverpfp.png';
import axios from 'axios';
import Cookies from "js-cookie";
import '../css/admin-mod-panel.css'

//components
import getUserData from './getUserData';

const RenderModeratorPanel = ({ postId, handleDelete }) => {
    const [enabled, setEnabled] = useState(false);
    const [showContextMenu, setShowContextMenu] = useState(false);
    const [nickname, email, mobile, pfpUrl, lvl, exp, expNeeded, status, userId, roleId, verified, roleBadgeComponent, renderVerifiedBadge] = getUserData();

    useEffect(() => {
        const checkRole = () => {
            if(roleId > 0){
                setEnabled(true);
            } else {
                setEnabled(false);
            }
        }
        checkRole();
    }, [roleId]);

    return (
        <div className={`context-menu ${enabled ? 'show' : ''}`}>
            <div className="context-menu-title">
                PostId: {postId}
            </div>
            <button className="context-menu-item context-menu-delete" onClick={handleDelete}>
                <i className="fas fa-trash"></i><span className="sr-only">(current)</span> Delete
            </button>
        </div>
    );
};

export default RenderModeratorPanel;