import React, { useState, useEffect } from 'react';
import './css/bootstrap.min.css';
import './css/main.css';
import './css/now-ui-kit.css';
import './App.css';
import './css/loginAndRegister.css';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import LimroLogo from './assets/fullsizelogo.png'
import PasswordStrengthBar from 'react-password-strength-bar';
import axios from 'axios';

import { useLoginManager } from './components/loginManager';
import { useBackendAddress } from './components/backendAddress';

const RegisterPage = ({ showNavAndFoot, setShowNavAndFoot, showLogregFoot, setShowLogregFoot }) => {
    const { t } = useTranslation();
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [passwordRepeat, setPasswordRepeat] = useState('');
    const [passwordScore, setPasswordScore] = useState('');
    const [regButtonDisabled, setRegButtonDisabled] = useState(true);
    const [backendResponse, setBackendResponse] = useState('');

    const { loggedIn, logoutFunction } = useLoginManager();

    const { backendAddress } = useBackendAddress();

    const handleKeyPress = (e) => {
        if(e.key === 'Enter' && !regButtonDisabled){
            handleRegister();
        }
    }

    useEffect(() => {
        if (email.trim() && password.trim() && passwordRepeat.trim()) {
            if(passwordScore >= 2){
                setRegButtonDisabled(false);
            } else {
                setRegButtonDisabled(true);
            }
        } else {
            setRegButtonDisabled(true);
        }
    }, [email, password, passwordRepeat, passwordScore]);

    const handleRegister = async () => {
        try {
            const response = await axios.post(`${backendAddress}/auth/register`, {
                email,
                username,
                password,
                passwordRepeat,
                passwordScore
            });
            console.log(response.data);
            if (response.status === 201) {
                window.location.href = 'https://limro.top/login';
            }
        } catch (error) {
            console.error('Error registering user:', error);
            if (error.response && error.response.data && error.response.data.message) {
                setBackendResponse(error.response.data.message);
            } else {
                setBackendResponse('An error occurred during registration');
            }
        }
    }

    setShowNavAndFoot(false);
    setShowLogregFoot(true);

    useEffect(() => {
        if (loggedIn) {
            navigateToHome();
        }
    }, [loggedIn]);

    const navigateToHome = () => {
        window.location.href = '/';
    }

    return (
        <div className='fullscreen_container'>
            <Helmet>
                <title>Register | LimroNet</title>
            </Helmet>
            <div className="heading3">
                <h1 className='display-5 lr-title'>Start your journey here at <img src={LimroLogo} className='lr_logo'></img></h1>
                <div className='lr_container'>
                    <h1 className="display-5 lr-2-title">Limro[ID]</h1>
                    <h1 className="lr_inp_label">Email</h1>
                    <input type='email' className='input_lr' onChange={(e) => {setEmail(e.target.value);}} onKeyPress={handleKeyPress}></input>
                    <h1 className="lr_inp_label">Username</h1>
                    <input type='username' className='input_lr' onChange={(e) => {setUsername(e.target.value);}} onKeyPress={handleKeyPress}></input>
                    <h1 className="lr_inp_label">Password</h1>
                    <input type='password' className='input_lr' onChange={(e) => {setPassword(e.target.value);}} onKeyPress={handleKeyPress}></input>
                    <div className='pass_checker_container'>
                        <PasswordStrengthBar password={password} onChangeScore={(score) => setPasswordScore(score)} />
                    </div>
                    <h1 className="lr_inp_label">Repeat Password</h1>
                    <input type='password' className='input_lr' onChange={(e) => {setPasswordRepeat(e.target.value);}} onKeyPress={handleKeyPress}></input>
                    {passwordRepeat !== password && (
                        <div>
                            <h1 className="reg_notmatch_label">Passwords do not match!</h1>
                        </div>
                    )}
                    {backendResponse !== '' && (
                        <div>
                            <h1 className="reg_notmatch_label">{backendResponse}</h1>
                        </div>
                    )}
                    <div>
                        <button className="btn2" onClick={handleRegister} disabled={regButtonDisabled}>Register</button>
                    </div>
                    <h1 className="reg_label">Already have an account?<a href="/login"><div><p>Login</p></div></a></h1>
                </div>
            </div>
        </div>
    );
};

export default RegisterPage;