import React, { useState, useEffect } from 'react';
import './css/bootstrap.min.css';
import './css/main.css';
import './css/now-ui-kit.css';
import './App.css';
import './css/features.css';
import './css/direct-messages.css';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import Cookies from 'js-cookie';
import axios from 'axios';

import getUserData from './components/getUserData';
import { useLoginManager } from './components/loginManager';
import { useBackendAddress } from './components/backendAddress';

const DirectMessages = () => {
    const { t } = useTranslation();
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [messages, setMessages] = useState([]);
    const { loggedIn, logoutFunction } = useLoginManager();

    const { backendAddress } = useBackendAddress();

    const [nickname, email, mobile, pfpUrl, lvl, exp, expNeeded, status, userId, roleId, verified, roleBadgeComponent, renderVerifiedBadge] = getUserData();

    useEffect(() => {
        fetchUsers();
    }, []);

    useEffect(() => {
        if (selectedUser) {
            fetchMessages(selectedUser.id);
        }
    }, [selectedUser]);

    const fetchUsers = async () => {
        try {
            const sessionId = Cookies.get('session');
            const response = await axios.get(`${backendAddress}/messages/users?userId=${userId}`, {
                headers: {
                    Authorization: `Bearer ${sessionId}`,
                },
            });
            if (response.status === 401) {
                await logoutFunction();
            }
            setUsers(response.data.users);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    const fetchMessages = async (otherUserId) => {
        try {
            const sessionId = Cookies.get('session');
            const response = await axios.get(`${backendAddress}/messages/${otherUserId}?userId=${userId}`, {
                headers: {
                    Authorization: `Bearer ${sessionId}`,
                },
            });
            if (response.status === 401) {
                logoutFunction();
            }
            setMessages(response.data.messages);
        } catch (error) {
            console.error('Error fetching messages:', error);
        }
    };

    return (
        <div className='fullscreen_container'>
            <Helmet>
                <title>Direct Messages | LimroNet</title>
            </Helmet>
            <div className="direct-messages">
                <div className="user-list">
                    {users.map(user => (
                        <div
                            key={user.id}
                            className={`user-item ${selectedUser && selectedUser.id === user.id ? 'selected' : ''}`}
                            onClick={() => setSelectedUser(user)}
                        >
                            {user.username}
                        </div>
                    ))}
                </div>
                <div className="message-content">
                    {selectedUser ? (
                        messages.length > 0 ? (
                            messages.map(message => (
                                <div key={message.id} className={`message ${message.senderId === selectedUser.id ? 'received' : 'sent'}`}>
                                    <p>{message.content}</p>
                                    <small>{new Date(message.timestamp).toLocaleString()}</small>
                                </div>
                            ))
                        ) : (
                            <p>{t('No messages yet')}</p>
                        )
                    ) : (
                        <p>{t('Choose user')}</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default DirectMessages;